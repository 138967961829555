body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  height: 100%;
}

.book_table_inner {
	background: #f5f5f5;
	box-shadow: 0px 3px 57px 0px rgba(0, 0, 0, 0.18);
	clear: both;
	padding: 35px 32px 35px 32px;
	margin-top: -25px !important;
	z-index: 20;
	position: relative;
}
    
    @media (max-width: 767px){
      #booking-form{
      display:flex;
      flex-direction:column;
        /* align-items:center; */
        justify-content: space-between;
      }
      
      #booking-form .book_table_item{
        margin-bottom: 15px;
        
      }
    }

.bg-center {
  background-position: center!important;
}

.booking_form label{
  color: #000000;
}
.ml-35{
  margin-left: 3.5rem!important;
}
.font-18{
  font-size: 18px!important;
}
.nav-scroll .logo-img{
  width: 110px!important;
}
.submitBtn{
  font-weight: 400;
    font-family: 'Gilda Display', serif;
    text-transform: uppercase;
    background: #ff0000;
    color: #fff;
    padding: 8px 24px;
    margin: 0;
    position: relative;
    font-size: 15px;
    letter-spacing: 3px;
}